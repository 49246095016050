import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "checkbox-image-button--vertical__container"
}
const _hoisted_2 = ["checked"]
const _hoisted_3 = {
  key: 1,
  class: "ml-2 mr-1"
}
const _hoisted_4 = {
  key: 1,
  class: "checkbox-image-button--horizontal__container card"
}
const _hoisted_5 = { class: "checkbox-image-button__image" }
const _hoisted_6 = { class: "checkbox-image-button__label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!

  return (_openBlock(), _createBlock(_component_a_checkbox, {
    class: "checkbox-image-button",
    value: _ctx.value
  }, {
    default: _withCtx(() => [
      (_ctx.direction === 'vertical')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", {
              class: _normalizeClass(["checkbox-image-button__image card", [_ctx.noImageBorder && 'no-image-border']]),
              style: _normalizeStyle({ height: `${_ctx.height}px` })
            }, [
              _createElementVNode("div", {
                class: "checkbox-image-button__image__fg",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-image-click')))
              }, [
                _renderSlot(_ctx.$slots, "foreground")
              ]),
              _createElementVNode("div", {
                class: _normalizeClass(["checkbox-image-button__image__bg", [_ctx.hasForeground && !_ctx.disableBackgroundFade && 'faded']])
              }, [
                _renderSlot(_ctx.$slots, "image")
              ], 2)
            ], 6),
            _createElementVNode("div", {
              class: _normalizeClass(["checkbox-image-button__label", [_ctx.showCheckbox && 'text-left']])
            }, [
              (_ctx.showCheckbox)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (!_ctx.useRadio)
                      ? (_openBlock(), _createElementBlock("input", {
                          key: 0,
                          type: "checkbox",
                          checked: _ctx.selected
                        }, null, 8, _hoisted_2))
                      : (_openBlock(), _createBlock(_component_a_radio, {
                          key: 1,
                          type: "radio",
                          checked: _ctx.selected,
                          class: "mr-0"
                        }, null, 8, ["checked"]))
                  ], 64))
                : _createCommentVNode("", true),
              (!!_ctx.$slots['label-icon'])
                ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                    _renderSlot(_ctx.$slots, "label-icon")
                  ]))
                : _createCommentVNode("", true),
              _createTextVNode(" " + _toDisplayString(_ctx.label), 1)
            ], 2)
          ]))
        : _createCommentVNode("", true),
      (_ctx.direction === 'horizontal')
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", {
                class: "checkbox-image-button__image__bg",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('on-image-click')))
              }, [
                _renderSlot(_ctx.$slots, "image")
              ])
            ]),
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.label), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["value"]))
}