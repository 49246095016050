import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KycIcon = _resolveComponent("KycIcon")!
  const _component_CheckboxImageInputItem = _resolveComponent("CheckboxImageInputItem")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_checkbox_group = _resolveComponent("a-checkbox-group")!
  const _component_FormItem = _resolveComponent("FormItem")!

  return (_openBlock(), _createBlock(_component_FormItem, {
    label: _ctx.$attrs.label,
    name: _ctx.$attrs.name,
    value: _ctx.$attrs.value,
    rules: _ctx.$attrs.rules,
    extra: _ctx.$attrs.extra,
    help: _ctx.$attrs.help
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_checkbox_group, _mergeProps({ class: "checkbox-image-group" }, _ctx.$attrs, {
        value: _ctx.innerValue,
        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.innerValue) = $event)),
        onChange: _ctx.handleChange
      }), {
        default: _withCtx(() => [
          _createVNode(_component_a_row, {
            gutter: _ctx.$attrs.gutter || [32, 16]
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
                return (_openBlock(), _createBlock(_component_a_col, {
                  key: option.value,
                  xxxl: _ctx.$attrs.xxxl,
                  span: _ctx.$attrs.span,
                  xs: _ctx.$attrs.xs,
                  sm: _ctx.$attrs.sm,
                  md: _ctx.$attrs.md,
                  lg: _ctx.$attrs.lg,
                  xl: _ctx.$attrs.xl,
                  xxl: _ctx.$attrs.xxl
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CheckboxImageInputItem, {
                      value: option.value,
                      label: option.label,
                      direction: _ctx.direction,
                      "show-checkbox": _ctx.showCheckboxes,
                      selected: _ctx.innerValue?.includes(option.value) ?? false,
                      "use-radio": _ctx.singleSelect,
                      "no-image-border": _ctx.noImageBorder,
                      height: _ctx.itemHeight,
                      "disable-background-fade": _ctx.disableBackgroundFade,
                      onOnImageClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-image-click')))
                    }, _createSlots({
                      image: _withCtx(() => [
                        _createVNode(_component_KycIcon, {
                          name: option.bgIconName
                        }, null, 8, ["name"])
                      ]),
                      _: 2
                    }, [
                      (option.labelIcon)
                        ? {
                            name: "label-icon",
                            fn: _withCtx(() => [
                              _createVNode(_component_KycIcon, {
                                name: option.labelIcon
                              }, null, 8, ["name"])
                            ])
                          }
                        : undefined,
                      (option.fgIconName)
                        ? {
                            name: "foreground",
                            fn: _withCtx(() => [
                              _createVNode(_component_KycIcon, {
                                name: option.fgIconName
                              }, null, 8, ["name"])
                            ])
                          }
                        : undefined
                    ]), 1032, ["value", "label", "direction", "show-checkbox", "selected", "use-radio", "no-image-border", "height", "disable-background-fade"])
                  ]),
                  _: 2
                }, 1032, ["xxxl", "span", "xs", "sm", "md", "lg", "xl", "xxl"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["gutter"])
        ]),
        _: 1
      }, 16, ["value", "onChange"])
    ]),
    _: 1
  }, 8, ["label", "name", "value", "rules", "extra", "help"]))
}