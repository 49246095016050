import gql from "graphql-tag";

export const productsGql = gql`
  query Products($input: ProductsInput) {
    products(input: $input) {
      ... on ProductResults {
        results {
          id
          name
          industry {
            id
            name
          }
        }
      }

      ... on ResponseErrors {
        __typename
        errors {
          __typename
          code
          message
          displayMessage

          ... on InvalidInputError {
            field
          }
        }
      }
    }
  }
`;
