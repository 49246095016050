
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    title: String,
    subtitle1: String,
    subtitle2: String,
  },
});
