import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "flex gap-4 flex-col md:flex-row"
}
const _hoisted_2 = {
  key: 0,
  class: "flex-grow"
}
const _hoisted_3 = {
  key: 1,
  class: "flex-grow"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_InputDropdown = _resolveComponent("InputDropdown")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, {
    title: 
      _ctx.t('What is your product (e.g. shampoo, lipstick, application etc)?')
    ,
    class: "kyc-product"
  }, {
    default: _withCtx(() => [
      (_ctx.productsLoading)
        ? (_openBlock(), _createBlock(_component_a_skeleton, {
            key: 0,
            title: null,
            paragraph: { rows: 2, width: '100%' },
            active: ""
          }))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_ctx.hasAvailableOptions)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_InputDropdown, {
                    name: "productId",
                    value: _ctx.productIdModel,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.productIdModel) = $event)),
                    options: _ctx.productDropdownOptions,
                    placeholder: _ctx.t('Please select one.'),
                    rules: [_ctx.makeRequiredRule(_ctx.t('Please select one.'))],
                    disabled: _ctx.productsLoading
                  }, null, 8, ["value", "options", "placeholder", "rules", "disabled"])
                ]))
              : _createCommentVNode("", true),
            (!_ctx.hasAvailableOptions || _ctx.productIdModel === _ctx.NONE_OF_THE_ABOVE)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_InputText, {
                    name: "productDescription",
                    value: _ctx.productDescriptionModel,
                    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.productDescriptionModel) = $event)),
                    placeholder: _ctx.t('Please specify'),
                    rules: [_ctx.makeRequiredRule(_ctx.t('This field is required.'))],
                    disabled: _ctx.productsLoading
                  }, null, 8, ["value", "placeholder", "rules", "disabled"])
                ]))
              : _createCommentVNode("", true)
          ]))
    ]),
    _: 1
  }, 8, ["title"]))
}