
import { defineComponent } from "vue";
import InputText from "@/shared/components/Forms/InputText.vue";
import Card from "@/shared/components/Card.vue";
import { makeRequiredRule } from "@/shared/utils/validators/commonValidators";
import { useI18n } from "vue-i18n";
import { useVModel } from "vue-composable";
import {
  useProducts,
  NONE_OF_THE_ABOVE,
} from "@/shared/composables/useProducts";
import InputDropdown from "@/shared/components/Forms/InputDropdown.vue";

export default defineComponent({
  props: {
    productId: String,
    productDescription: String,
    industryId: String,
  },
  setup(props) {
    const { t } = useI18n();
    const productIdModel = useVModel(props, "productId");
    const productDescriptionModel = useVModel(props, "productDescription");
    const industryIdModel = useVModel(props, "industryId");

    const { hasAvailableOptions, productDropdownOptions, productsLoading } =
      useProducts(industryIdModel);

    return {
      t,
      productIdModel,
      productDescriptionModel,
      makeRequiredRule,
      hasAvailableOptions,
      productDropdownOptions,
      productsLoading,
      NONE_OF_THE_ABOVE,
    };
  },
  components: {
    InputText,
    Card,
    InputDropdown,
  },
});
