import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "kyc-layout" }
const _hoisted_2 = { class: "kyc-layout__header text-center" }
const _hoisted_3 = { class: "kyc-layout__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_typography = _resolveComponent("a-typography")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.title)
        ? (_openBlock(), _createBlock(_component_a_typography_title, {
            key: 0,
            level: 2
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.title), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.subtitle1)
        ? (_openBlock(), _createBlock(_component_a_typography_title, {
            key: 1,
            level: 4,
            class: "mt-14 mb-0"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.subtitle1), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.subtitle2)
        ? (_openBlock(), _createBlock(_component_a_typography, {
            key: 2,
            class: "kyc-layout__subtitle-2"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.subtitle2), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}