
import CheckboxImageInputItem, {
  CheckboxImageInputDirection,
} from "@/shared/components/Forms/CheckboxImageInputItem.vue";
import FormItem from "@/shared/components/Forms/FormItem.vue";
import KycIcon from "@/shared/components/Icons/KycIcon.vue";
import { defineComponent, PropType } from "vue";
import { useVModel } from "vue-composable";

export interface SelectOptionWithImage {
  bgIconName: string;
  fgIconName?: string;
  labelIcon?: string;
  label: string;
  value: string;
}

export default defineComponent({
  setup(props) {
    const innerValue = useVModel(props, "value");

    const handleChange = (selectedItems: string[]) => {
      if (props.singleSelect) {
        const lastItem = selectedItems[selectedItems.length - 1];
        innerValue.value = lastItem;
      } else {
        innerValue.value = selectedItems;
      }
    };

    return { innerValue, handleChange };
  },
  props: {
    value: {
      type: [String, Array] as PropType<string | string[]>,
      required: true,
    },
    options: {
      type: Array as PropType<SelectOptionWithImage[]>,
      required: true,
    },
    direction: {
      type: String as PropType<CheckboxImageInputDirection>,
      default: "vertical",
    },
    singleSelect: Boolean,
    showCheckboxes: Boolean,
    noImageBorder: Boolean,
    disableBackgroundFade: Boolean,
    itemHeight: {
      type: Number,
      default: 152,
    },
  },
  emits: ["update:value", "on-image-click"],
  components: {
    CheckboxImageInputItem,
    KycIcon,
    FormItem,
  },
});
