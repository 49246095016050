
import { defineComponent, PropType } from "vue";

export type CheckboxImageInputDirection = "horizontal" | "vertical";

export default defineComponent({
  setup(_, { slots }) {
    const hasForeground = !!slots.foreground;

    return { hasForeground };
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    direction: {
      type: String as PropType<CheckboxImageInputDirection>,
      required: true,
    },
    height: Number,
    showCheckbox: Boolean,
    useRadio: Boolean,
    selected: Boolean,
    noImageBorder: Boolean,
    disableBackgroundFade: Boolean,
  },
  emits: ["on-image-click"],
});
