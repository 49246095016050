
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
// Business
import physicalProductsBg from "@/assets/kyc/physicalProductsBg.jpg";
import digitalProductsBg from "@/assets/kyc/digitalProductsBg.jpg";
import physicalProductsFg from "@/assets/kyc/physicalProductsFg.svg";
import digitalProductsFg from "@/assets/kyc/digitalProductsFg.svg";
// Industry
import beauty from "@/assets/kyc/beauty.jpg";
import fashion from "@/assets/kyc/fashion.jpg";
import food from "@/assets/kyc/food.jpg";
import lifestyle from "@/assets/kyc/lifestyle.jpg";
// Feelings
import calm from "@/assets/kyc/calm.jpg";
import confidence from "@/assets/kyc/confidence.jpg";
import happiness from "@/assets/kyc/happiness.jpg";
import belongingness from "@/assets/kyc/belongingness.jpg";
import successful from "@/assets/kyc/successful.jpg";
import comfort from "@/assets/kyc/comfort.jpg";
// Genders
import genderMale from "@/assets/kyc/genderMale.svg";
import genderFemale from "@/assets/kyc/genderFemale.svg";
import genderNonBinary from "@/assets/kyc/genderNonBinary.svg";
// General
import merchantLogoWhite from "@/assets/merchantLogoWhite.svg";
import playButtonWhite from "@/assets/kyc/playButtonWhite.svg";
// QuotationQuestionnaire
import shortVideoIcon from "@/assets/kyc/shortVideoIcon.svg";
import longVideoIcon from "@/assets/kyc/longVideoIcon.svg";
import topPerformerIcon from "@/assets/kyc/topPerformerIcon.svg";
import premiumPerformerIcon from "@/assets/kyc/premiumPerformerIcon.svg";
import noTravelIcon from "@/assets/kyc/noTravelIcon.svg";
import withTravelIcon from "@/assets/kyc/withTravelIcon.svg";
import regular from "@/assets/kyc/regular.svg";
import express from "@/assets/kyc/express.svg";
import videoIcon from "@/assets/kyc/videoIcon.svg";
import performerIcon from "@/assets/kyc/performerIcon.svg";
import megaphone from "@/assets/kyc/megaphone.svg";
import tiktok from "@/assets/kyc/tiktok.svg";
import instagram from "@/assets/kyc/instagram.svg";
import facebook from "@/assets/kyc/facebook.svg";
import web from "@/assets/kyc/web.svg";
import truck from "@/assets/kyc/truck.svg";
import refresh from "@/assets/kyc/refresh.svg";
import receipt from "@/assets/kyc/receipt.svg";
import userSearch from "@/assets/kyc/userSearch.svg";
import userLocked from "@/assets/kyc/userLocked.svg";
import pen from "@/assets/kyc/pen.svg";
import lightBulb from "@/assets/kyc/lightBulb.svg";
import calendar from "@/assets/kyc/calendar.svg";
import calendarNotAllowed from "@/assets/kyc/calendarNotAllowed.svg";

const KYC_ICON = {
  physicalProductsBg,
  digitalProductsBg,
  digitalProductsFg,
  physicalProductsFg,

  // Industry
  beauty,
  fashion,
  food,
  lifestyle,

  // Feelings
  calm,
  confidence,
  happiness,
  belongingness,
  successful,
  comfort,

  // Genders
  genderMale,
  genderFemale,
  genderNonBinary,

  // General
  merchantLogoWhite,
  playButtonWhite,

  // QuotationQuestionnaire
  shortVideoIcon,
  longVideoIcon,
  topPerformerIcon,
  premiumPerformerIcon,
  noTravelIcon,
  withTravelIcon,
  regular,
  express,
  videoIcon,
  performerIcon,
  megaphone,
  tiktok,
  instagram,
  facebook,
  web,
  truck,
  refresh,
  receipt,
  userSearch,
  userLocked,
  pen,
  lightBulb,
  calendar,
  calendarNotAllowed,
};

export type KycIconName = keyof typeof KYC_ICON;

export const getKycIcon = (iconName: KycIconName) => {
  return KYC_ICON[iconName];
};

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();

    return { t };
  },
  methods: { getKycIcon },
});
