import { productsGql } from "@/api/filters/products";
import {
  Products,
  ProductsVariables,
  Products_products_ProductResults,
} from "@/api/filters/__generated__/Products";
import { i18nTranslate } from "@/plugins/i18n";
import { DropdownOptionObject } from "@/shared/components/Forms/InputDropdown.vue";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { useQuery } from "@vue/apollo-composable";
import { capitalize, computed, Ref } from "vue";

export const NONE_OF_THE_ABOVE = "NONE_OF_THE_ABOVE";

export const useProducts = (industryIdRef?: Ref<string | undefined>) => {
  const { loading: productsLoading, result: productsResult } = useQuery<
    Products,
    ProductsVariables
  >(productsGql, () => ({
    input: { industryId: industryIdRef?.value || null },
  }));

  const products = computed(() => {
    return parseGqlResponse<Products_products_ProductResults>(
      "ProductResults",
      productsResult.value
    ).data?.results;
  });

  const productDropdownOptions = computed<DropdownOptionObject[]>(() =>
    (
      products.value?.map((product) => {
        const productIndustry = product?.industry?.name ?? "";
        const productName = capitalize(product?.name ?? "");

        // Show industry when industryId filter is not provided
        return {
          label: !industryIdRef?.value
            ? `${productIndustry}: ${productName}`
            : productName,
          value: product?.id ?? "",
        };
      }) ?? []
    ).concat([
      { label: i18nTranslate("None of the Above"), value: NONE_OF_THE_ABOVE },
    ])
  );

  const hasAvailableOptions = computed(() => !!products.value?.length);

  return {
    products,
    productsLoading,
    productDropdownOptions,
    hasAvailableOptions,
  };
};
